import React from "react"

import Layout from "@src/components/Layout"
import Callout from "@src/components/Callout"

import PricingBreakdown from "./components/PricingBreakdown"
import PricingFAQs from "./components/PricingFAQs"

import OG from "@src/icons/og/OG_Pricing.png"
import * as styles from "./Pricing.module.scss"
import PricingCards from "./components/PricingCards"

const Pricing = ({ location }) => {
  const [interval, setInterval] = React.useState("MONTHLY")
  return (
    <Layout
      title="Pricing"
      description="An affordable price for a powerful builder."
      url="https://canonic.dev/pricing"
      location={location}
      switchOnId="faq"
      ogImage={OG}
      className={styles.layout}
    >
      <div className={styles.pricing}>
        <PricingCards interval={interval} setInterval={setInterval} />
        <PricingBreakdown interval={interval} />
        <PricingFAQs />
        <Callout
          className={styles.callout}
          location={location}
          title="pricing"
          black
        />
      </div>
    </Layout>
  )
}

export default Pricing
