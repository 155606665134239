import React from "react"
import * as styles from "./Pricing.module.scss"
import { gsap, ScrollTrigger } from "gsap/all"
import { Power2, Power3 } from "gsap"
gsap.registerPlugin(ScrollTrigger)

export default function Pricing({ interval, setInterval }) {
  return (
    <section className={styles.section}>
      <div className={styles.sectionHeader}>
        <h1 className={styles.sectionTitle}>
          Get started for free.
          <br />
          <strong>Unlimited projects. Unlimited users</strong>
        </h1>
        <div className={styles.tabs}>
          <div
            onClick={() => setInterval("MONTHLY")}
            className={`${styles.tab} ${
              interval === "MONTHLY" ? styles.active : ""
            }`}
          >
            Monthly
          </div>
          <div
            onClick={() => setInterval("ANNUALLY")}
            className={`${styles.tab} ${
              interval === "ANNUALLY" ? styles.active : ""
            }`}
          >
            Annually
          </div>
        </div>
      </div>
      <div className={styles.pricingCards}>
        <PricingCard
          title="Essential"
          pricing="Free"
          features={["Unlimited Projects", "Unlimited Users", "1GB Storage"]}
        />
        <PricingCard
          title="Solo"
          pricing={interval === "MONTHLY" ? "$28/mo" : "$280/yr"}
          features={[
            "<strong>Everything in Essential + </strong>",
            "20GB Storage",
            "1 Collaborators",
            "7 days of Revision History",
            "Unlimited Custom Domain",
            "Unlimited Environments",
            "Code Export",
          ]}
        />
        <PricingCard
          title="Pro"
          pricing={interval === "MONTHLY" ? "$120/mo" : "$1200/yr"}
          features={[
            "<strong>Everything in Essential + </strong>",
            "100GB Storage",
            "5 Collaborators",
            "60 days of Revision History",
            "Unlimited Custom Domain",
            "Unlimited Environments",
            "Code Export",
          ]}
        />

        <PricingCard
          title="Enterprise"
          pricing="Custom"
          features={[
            "<strong>Everything in Pro +</strong>",
            "Unlimited Collaborators",
            "100GB Storage",
            "Unlimited Revision History",
            "Unlimited Custom Domain",
            "Unlimited Environments",
            "Audit Logs",
            "Dedicated Account Manager",
          ]}
        />
      </div>
    </section>
  )
}

function PricingCard({ title, pricing, features }) {
  return (
    <article className={styles.pricingCard}>
      <h3 className={styles.title}>{title}</h3>
      <h4 className={styles.price}>{pricing}</h4>
      <ul className={styles.limits}>
        {features.map(f => (
          <li
            key={f}
            className={styles.limit}
            dangerouslySetInnerHTML={{ __html: f }}
          />
        ))}
      </ul>
    </article>
  )
}
