import { string } from "prop-types"
import Text from "@src/components/Text"
import React from "react"

import * as styles from "./PricingFAQs.module.scss"

function FAQ({ faq, index, toggleFAQ }) {
  const faqOpenClassname = faq.open ? styles.open : ""
  return (
    <article
      className={`${styles.faqsFaq} ${faqOpenClassname}`}
      key={index}
      onClick={() =>
        typeof toggleFAQ === "function" ? toggleFAQ(index) : null
      }
    >
      <h4 className={styles.faqsFaqQuestion}>
        <strong>{faq.question}</strong>
      </h4>
      <p className={styles.faqsFaqAnswer}>{faq.answer}</p>
    </article>
  )
}

export default FAQ
