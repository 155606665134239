// extracted by mini-css-extract-plugin
export var Triangle = "PricingFAQs-module--Triangle--2v8KL";
export var faqRow = "PricingFAQs-module--faqRow--3o_ga";
export var faqRowTitle = "PricingFAQs-module--faqRowTitle--1c-4Y";
export var faqs = "PricingFAQs-module--faqs--UH2BU";
export var faqsFaq = "PricingFAQs-module--faqsFaq--yLMua";
export var faqsFaqAnswer = "PricingFAQs-module--faqsFaqAnswer--23ddt";
export var faqsFaqQuestion = "PricingFAQs-module--faqsFaqQuestion--389RL";
export var open = "PricingFAQs-module--open--1Vawx";
export var pricingFaqs = "PricingFAQs-module--pricingFaqs--1yQRu";
export var section = "PricingFAQs-module--section--1KXRn";