// extracted by mini-css-extract-plugin
export var active = "Pricing-module--active--KSpU0";
export var limit = "Pricing-module--limit--3sJMY";
export var limits = "Pricing-module--limits--p_5qT";
export var price = "Pricing-module--price--1Vs9A";
export var pricingCard = "Pricing-module--pricingCard--3_5CG";
export var pricingCards = "Pricing-module--pricingCards--VxruO";
export var section = "Pricing-module--section--F11wq";
export var sectionHeader = "Pricing-module--sectionHeader--1dKTt";
export var sectionTitle = "Pricing-module--sectionTitle--2ucHO";
export var tab = "Pricing-module--tab--3lUBf";
export var tabs = "Pricing-module--tabs--34a77";
export var title = "Pricing-module--title--1y0jp";