import React from "react"
import Link from "gatsby-plugin-transition-link"

import * as styles from "./PricingBreakdown.module.scss"

const ROWS = ({ interval }) => [
  {
    title: "Cost",
    free: "Free",
    solo: interval === "MONTHLY" ? "28$/mo" : "280$/yr",
    pro: interval === "MONTHLY" ? "120$/mo" : "1200$/yr",
    enterprise: "Custom",
  },
  {
    title: "Users",
    free: "Unlimited",
    solo: "Unlimited",
    pro: "Unlimited",
    enterprise: "Unlimited",
  },
  {
    title: "Collaborators",
    free: "0",
    solo: "1",
    pro: "5",
    enterprise: "Unlimited",
  },
  {
    title: "Custom domains",
    free: "No",
    solo: "Unlimited",
    pro: "Unlimited",
    enterprise: "Unlimited",
  },
  {
    title: "Environments",
    free: "1",
    solo: "Unlimited",
    pro: "Unlimited",
    enterprise: "Unlimited",
  },
  {
    title: "Audit Logs",
    free: "No",
    solo: "No",
    pro: "No",
    enterprise: "Yes",
  },
  {
    title: "Code Export",
    free: "No",
    solo: "Yes",
    pro: "Yes",
    enterprise: "Yes",
  },
  {
    title: "Version History",
    free: "7 Days",
    solo: "7 Days",
    pro: "2 Months",
    enterprise: "Unlimited",
  },
  {
    title: "On Premises",
    free: "Self Hosted",
    solo: "Self Hosted",
    pro: "Self Hosted",
    enterprise: "Assisted",
  },
  {
    title: "Projects",
    free: "Unlimited",
    solo: "Unlimited",
    pro: "Unlimited",
    enterprise: "Unlimited",
  },
  {
    title: "Support",
    free: "Discord",
    solo: "Email Support",
    pro: "Email Support",
    enterprise: "24h",
  },
  {
    title: "SLA",
    free: "None",
    solo: "99.9%",
    pro: "99.9%",
    enterprise: "99.999%",
  },

  {
    title: "",
    free: "",
    solo: "",
    pro: "",
    enterprise: (
      <div className={styles.button}>
        {" "}
        <Link to="/get-a-demo" entry={{ length: 0.3 }} exit={{ length: 0.3 }}>
          Contact Us
        </Link>
      </div>
    ),
  },
]

const Header = () => (
  <div className={styles.pricingBreakdownTableHeader}>
    <div className={styles.pricingBreakdownTableHeaderItem} />
    <div className={styles.pricingBreakdownTableHeaderItem}>
      Essential (Free)
    </div>
    <div className={styles.pricingBreakdownTableHeaderItem}>Solo</div>
    <div className={styles.pricingBreakdownTableHeaderItem}>Pro</div>
    <div className={styles.pricingBreakdownTableHeaderItem}>Advanced</div>
  </div>
)

const ColRow = ({ title, free, solo, pro, enterprise }) => (
  <div className={styles.pricingBreakdownTableRow}>
    <div className={styles.pricingBreakdownTableRowItem}>{title}</div>
    <div className={styles.pricingBreakdownTableRowItem}>{free}</div>
    <div className={styles.pricingBreakdownTableRowItem}>{solo}</div>
    <div className={styles.pricingBreakdownTableRowItem}>{pro}</div>
    <div className={styles.pricingBreakdownTableRowItem}>{enterprise}</div>
  </div>
)

const PricingBreakdown = ({ interval }) => {
  return (
    <div className={styles.pricingBreakdownTable}>
      <Header />
      {ROWS({ interval }).map(row => (
        <ColRow {...row} />
      ))}
    </div>
  )
}

export default PricingBreakdown
