import React from "react"

import * as styles from "./PricingFAQs.module.scss"
import useMobile from "../../../../V2/utils/isMobile"
import FAQ from "./FAQ"

const PricingFAQs = () => {
  const isMobile = useMobile()
  const [faqs, setFaqs] = React.useState([
    // {
    //   question: "How does the free trial work?",
    //   answer:
    //     "The free trial provides you with complete access to all the features of the selected plan. You can use all these features for the entire duration of your trial, after which you will have to renew. If you don't, your project is automatically downgraded to the free plan.",
    //   open: false,
    // },
    // {
    //   question: "What happens at the end of my free trial?",
    //   answer:
    //     "After your free trial ends, you are automatically downgraded to the free plan. You can add your credit card to automatically renew when your free trial ends to ensure uninterrupted service.",
    //   open: false,
    // },
    // {
    //   question: "Can I change/cancel my plan later?",
    //   answer:
    //     "You can change your plan at any given time. You will be charged a prorated fee for that month and the new plan's fee the subsequent months.  In case you cancel, you will be downgraded to the free plan, you’ll lose access to the paid projects/features. Any add-ons associated with your previous plan will also be removed either you cancel or change.",
    //   open: false,
    // },
    // {
    //   question: "How will I get charged if cancel my plan later?",
    //   answer:
    //     "The prorated charges for the remaining days of your billing cycle will get stored in your credit balance which is not refundable. It’ll be automatically applied in the next set of paid services you subscribe to.",
    //   open: false,
    // },
    {
      question: "What payment methods do you support?",
      answer:
        "We support all major credit cards globally through our payment gateway partner.",
      open: false,
    },
    {
      question: "What's the difference between a collaborator and a user?",
      answer:
        "Collaborator refers to another user that you invite to a project to help you build projects. Users refer to people who use the projects or tools that you build.",
      open: false,
    },
    // {
    //   question: "What happens if I exceed my plan's limits?",
    //   answer:
    //     "You will be charged for overages in case you exceed your plan's limits. Refer to the overage charges section above for more details.",
    //   open: false,
    // },
    {
      question: "Do you have discounts for non-profits?",
      answer: (
        <>
          Yes, we do! We believe Canonic can help non-profits on a variety of
          use cases. Contact us at{" "}
          <a href="mailto:sales@canonic.dev">sales@canonic.dev</a> with your use
          case to get access to some enterprise features for free.
        </>
      ),
      open: false,
    },
    {
      question: "I have other questions",
      answer: (
        <>
          Contact us at{" "}
          <a href="mailto:support@canonic.dev">support@canonic.dev</a> for any
          other questions.
        </>
      ),
      open: false,
    },
  ])

  const toggleFAQ = React.useCallback(index => {
    setFaqs(faqs =>
      faqs.map((faq, i) =>
        i === index ? { ...faq, open: !faq.open } : { ...faq, open: false }
      )
    )
  }, [])

  return (
    <section className={styles.faqRow}>
      <h2 className={styles.faqRowTitle}>FAQs</h2>
      <div className={styles.faqs}>
        {faqs.map((faq, i) => (
          <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </section>
  )
}

export default PricingFAQs
